import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';
import { Icon } from '../Icon';
import styles from './Panel.module.scss';

const cn = classnames.bind(styles);

export const Panel = memo(function Panel({
    className = '',
    type,
    collapse,
    children,
    header,
}: {
    className?: string;
    type?: string;
    collapse?: boolean;
    children?: React.ReactNode;
    header?: React.ReactNode;
}) {
    const [collapsed, setCollapsed] = useState<boolean>(Boolean(collapse));

    const onCollapse = useCallback(() => {
        return collapse !== undefined ? setCollapsed(!collapsed) : null;
    }, [collapse, collapsed]);

    return (
        <div className={cn('panel', className, type ? `panel-${type}` : undefined)}>
            {header && (
                <div className={cn('panel-header')} onClick={onCollapse} aria-hidden="true">
                    {header}{' '}
                    {collapse !== undefined &&
                        (collapsed ? <Icon icon="chevron-down" /> : <Icon icon="chevron-up" />)}
                </div>
            )}
            <div
                className={cn(
                    'panel-body',
                    { 'panel-body-collapse': collapse !== undefined },
                    { 'panel-body-collapse-closed': collapsed },
                )}
            >
                {children}
            </div>
        </div>
    );
});
