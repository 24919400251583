import 'bootstrap/dist/css/bootstrap.min.css';
import '@smartfolly/frontend.web-ui/assets/styles/index.scss';
import { useScript } from '@smartfolly/frontend.web-ui';

import './assets/styles/landing.scss';
import { Home } from './pages/Home';

import { pirschCode } from '../configs';

const pirschScriptOptions = {
    defer: true,
    id: 'pirschjs',
    dataCode: pirschCode,
};

function App() {
    useScript('https://api.pirsch.io/pirsch.js', pirschScriptOptions);

    return <Home />;
}

export default App;
