import { memo, ReactNode } from 'react';
import { Modal as ModalBootstrap } from 'react-bootstrap';

import classnames from 'classnames/bind';
import { Button, IButtonIcon } from '../Button';
import { Flex, FlexContainer } from '../Flex';
import { Icon } from '../Icon';

import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

interface ModalProps {
    show: boolean;
    onHide?: () => void;
    size?: 'sm' | 'lg' | 'xl';
    backdrop?: true | false | 'static';
    backdropClassName?: string;
    className?: string;
    closeButton?: ReactNode;
    header?: ReactNode;
    fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
    body?: ReactNode;
    footer?: ReactNode;
    children: ReactNode;
}

const headerWidth = { width: '100%' };

const iconLeft: IButtonIcon = {
    icon: <Icon icon="close" />,
    animation: 'none',
};

export const Modal = memo(function Modal({
    className,
    closeButton,
    header,
    body,
    footer,
    children,
    ...props
}: ModalProps) {
    return (
        <ModalBootstrap
            // Allow focusing children (such as inputs) inside of the modal
            enforceFocus={false}
            {...props}
            backdropClassName={cn('modal-backdrop', props.backdropClassName)}
            className={cn('modal', className)}
        >
            {header && (
                <ModalBootstrap.Header className={cn('modal-header')}>
                    <FlexContainer justify="space-between" align="center" style={headerWidth}>
                        <Flex grow={1}>
                            <ModalBootstrap.Title>{header}</ModalBootstrap.Title>
                        </Flex>
                        <Flex>
                            {closeButton ??
                                (props.onHide && (
                                    <Button
                                        color="default"
                                        size="medium"
                                        disabled={false}
                                        onClick={props.onHide}
                                        iconLeft={iconLeft}
                                        className={cn('modal-close-btn')}
                                    />
                                ))}
                        </Flex>
                    </FlexContainer>
                </ModalBootstrap.Header>
            )}
            <ModalBootstrap.Body className={cn('modal-content')}>
                {body}
                {children}
            </ModalBootstrap.Body>
            {footer && <ModalBootstrap.Footer>{footer}</ModalBootstrap.Footer>}
        </ModalBootstrap>
    );
});

export const Overlay = memo(function Overlay({ className, ...props }: ModalProps) {
    return (
        <Modal
            {...props}
            backdropClassName={cn('modal-overlay-backdrop')}
            className={cn('modal-overlay', className)}
            fullscreen
        />
    );
});
