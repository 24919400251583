import isEqual from 'lodash/isEqual';

import { Log } from '../Log';

import type { AsyncStorage } from '../types';

import { MemoStorageManager } from './MemoStorageManager';

import type { BaseCollectionItem, CollectionLoader, CollectionLoaderListener } from './types';

type MemoCollectionLoaderOptions<Item extends BaseCollectionItem> = {
    /**
     * Key used to store the fetched collection items with.
     */
    key: string;
    /**
     * Storage used to store the fetched collection items in.
     */
    storage: AsyncStorage;
    /**
     * Amount of items to be fetched in one portion (i.e. first top items & each more items call)
     */
    limit: number;
    /**
     * Fetcher used to load the top items and receive updates.
     */
    topItemsFetcher: (listener: CollectionLoaderListener<Item>) => void;
    /**
     * Fetcher to load more items.
     */
    moreItemsFetcher: (after: Item) => Promise<Item[]>;
    /**
     * Stop running the fetcher if needed (optional).
     */
    stopItemsFetching?: () => void;
};

const log = new Log('MemoCollectionLoader');

// Let's provide an ID to let MemoStorageManager identify the source of the storage
const storageManagerID = 'MemoCollectionLoader';
// Let's limit the MemoStorageManager to 100 last items to cache as per LRU approach
const storageManagerLimit = 100;

export type MemoCollectionLoaderListener<Item> = (
    error: Error | undefined,
    items?: Item[],
    memoized?: boolean, // either the listener returned a memoized result or not
) => void;

/**
 * `MemoCollectionLoader` is an example of CollectionLoader which can be used to load the items
 * and at the same time cache the result in storage in order to reuse it next time we need it.
 */
export class MemoCollectionLoader<Item extends BaseCollectionItem>
    implements CollectionLoader<Item>
{
    /**
     * An error instance which might occur when fetching the collection.
     */
    private fetchError?: Error;

    /**
     * A key value for storage passed in the constructor.
     */
    private key: string;

    /**
     * Amount of items to be fetched in one portion passed in the constructor.
     */
    private limit: number;

    /**
     * Top items fetcher passed in the constructor.
     */
    private topItemsFetcher: (listener: CollectionLoaderListener<Item>) => void;

    /**
     * More items fetcher passed in the constructor.
     */
    private moreItemsFetcher: (after: Item) => Promise<Item[]>;

    /**
     * A function to stop fetching the collection.
     */
    private stopItemsFetching?: () => void;

    /**
     * A listener which should be triggered each time the top items are loaded or updated.
     */
    private listener?: MemoCollectionLoaderListener<Item>;

    /**
     * An indicator that the collection has started to load with items fetcher.
     */
    private loading: boolean = false;

    /**
     * An indicator that the top items of the collection have been loaded with the fetcher.
     */
    private topItemsLoaded: boolean = false;

    /**
     * An indicator that the collection can load more items.
     */
    private canLoadMoreItems: boolean = false;

    /**
     * A storage to cache the top loaded items in.
     */
    private storageManager: MemoStorageManager;

    /**
     * Top items of the collection (fetched with the `topItemsFetcher`).
     */
    private topItems?: { [key: string]: Item };

    /**
     * More items of the collection (fetched with the `moreItemsFetcher`).
     */
    private moreItems?: { [key: string]: Item };

    /**
     * Saved top items array in the storage. Used to avoid re-saving the same items.
     */
    private savedTopItems: Item[] = [];

    public constructor({
        key,
        storage,
        limit,
        topItemsFetcher,
        moreItemsFetcher,
        stopItemsFetching,
    }: MemoCollectionLoaderOptions<Item>) {
        this.key = key;

        this.limit = limit;

        this.topItemsFetcher = topItemsFetcher;
        this.moreItemsFetcher = moreItemsFetcher;
        if (stopItemsFetching) {
            this.stopItemsFetching = stopItemsFetching;
        }

        this.storageManager = new MemoStorageManager({
            id: storageManagerID,
            storage,
            storeLimit: storageManagerLimit,
        });
    }

    // Getters
    public get items(): Item[] {
        // Combine existing items by assigning the MOST ACTUAL items at last (which are `topItems`).
        const itemsDictionary = { ...this.moreItems, ...this.topItems };
        // Sort all the items by the `time` in DESCENDING order.
        return Object.values(itemsDictionary).sort((a, b) => b.time - a.time);
    }

    public get error(): Error | undefined {
        return this.fetchError;
    }

    public get hasLoadedTopItems(): boolean {
        return this.topItemsLoaded;
    }

    public get isActive(): boolean {
        return !!this.listener;
    }

    public get isLoading(): boolean {
        return this.loading;
    }

    public get canLoadMore(): boolean {
        return this.canLoadMoreItems;
    }

    // Actions
    public reset() {
        // Delete the loaded collection items (top & more)
        delete this.topItems;
        delete this.moreItems;

        // Delete the fetch error
        delete this.fetchError;

        // Mark the loader has not loaded the top items
        this.topItemsLoaded = false;

        // Mark the loader is not loading
        this.loading = false;

        // Unsubscribe the fetchers
        this.unsubscribe();
    }

    public prepare() {
        // N.B. This method should be called before the subscription since
        // this semaphore is required for the correct work of MultiLoader!
        this.loading = true;
    }

    public async subscribe(listener: MemoCollectionLoaderListener<Item>) {
        // Check if the subscription can take place
        if (this.listener != null) {
            // TODO: Think of providing an ability to subscribe on changes from multiple spots!
            const error = new Error('Attempt to subscribe on the same loader twice!');
            log.error('Failed to load the collection with error:', error);
            this.fetchError = error;
            listener(error);
            return;
        }

        // Assign the listener and activate the subscription.
        this.listener = listener;

        // Check if ready to load the collection
        if (!this.isLoading) {
            const error = new Error('Not prepared to load the collection');
            log.error('Failed to load the collection with error:', error);
            this.fetchError = error;
            listener(error);
            return;
        }

        // Process the items cached in the storage first
        (async () => {
            try {
                // Do not load the cached items if they're already been fetched
                // e.g. when unsubscribed the loader and re-subscribed it again
                if (this.topItems) {
                    return;
                }

                // Load the cached items from the storage
                const cachedItems = await this.storageManager.getItem(this.key);

                // Check the cached items are not empty and set them as the top items
                // only if they haven't been loaded yet with the fetcher
                if (cachedItems != null && !this.topItems) {
                    // Find the saved top items array
                    this.savedTopItems = JSON.parse(cachedItems) as Item[];

                    // Fill the top items dictionary with it
                    this.topItems = this.savedTopItems.reduce<{
                        [key: string]: Item;
                    }>((acc, item) => {
                        acc[item.key] = item;
                        return acc;
                    }, {});

                    // Complete loading the cached collection from the storage
                    this.completeLoadingCollection(false);
                }
            } catch (error) {
                log.error(
                    'Failed to get the cached collection from the storage with error:',
                    error,
                );
                // Do nothing, but log an error
            }
        })();

        // Load the top items with the fetcher
        this.topItemsFetcher((error: Error | undefined, items: Item[] | undefined) => {
            if (error) {
                log.error('Failed to fetch the collection with error:', error);

                // Set the fetch error
                this.fetchError = error;

                // Complete loading with error
                this.completeLoadingCollection(true);
                return;
            }

            log.debug('Fetched items:', items);

            // Delete the fetch error
            delete this.fetchError;

            if (items) {
                // Set the fetched top items
                const fetchedTopItems = items.reduce<{
                    [key: string]: Item;
                }>((acc, item) => {
                    acc[item.key] = item;
                    return acc;
                }, {});

                // Check if the top items already have been loaded with the fetcher
                if (!this.topItemsLoaded) {
                    // Mark the items have been loaded with the fetcher
                    this.topItemsLoaded = true;

                    // Set the `canLoadMoreItems` flag in case we query items for the first time
                    this.canLoadMoreItems = items.length >= this.limit;

                    // Reassign the top items with the fetched items
                    this.topItems = fetchedTopItems;
                } else {
                    // Combine existing top items with the updates received from the fetcher
                    this.topItems = { ...this.topItems, ...fetchedTopItems };
                }

                // Complete loading the collection
                this.completeLoadingCollection(true);
            }
        });
    }

    public unsubscribe() {
        if (this.stopItemsFetching) {
            this.stopItemsFetching();
        }

        if (this.listener) {
            delete this.listener;
        }
    }

    public async loadMore(): Promise<void> {
        if (this.isLoading || !this.canLoadMore) {
            // Already loading or cannot load more
            return;
        }

        const { lastItem } = this;
        if (!lastItem) {
            // Do not have the last item
            return;
        }

        // Mark the loader to be loading items by preparing it
        this.prepare();

        try {
            // Load more items after the last one as a dictionary
            const moreItems = (await this.moreItemsFetcher(lastItem)).reduce<{
                [key: string]: Item;
            }>((acc, item) => {
                acc[item.key] = item;
                return acc;
            }, {});

            // Update more items dictionary with the loaded items
            this.moreItems = { ...this.moreItems, ...moreItems };

            // Check if can load more items
            this.canLoadMoreItems = Object.keys(moreItems).length >= this.limit;
        } catch (error) {
            log.error('Failed to load more collection items with error:', error);
            this.fetchError = error as Error;
        } finally {
            // Complete loading the collection
            this.completeLoadingCollection(true);
        }
    }

    // Internals
    private get lastItem(): Item | undefined {
        const { items } = this;
        return items[items.length - 1];
    }

    private completeLoadingCollection(loadedWithFetcher: boolean) {
        if (loadedWithFetcher) {
            // Mark the top items of the collections have been loaded with the fetcher (for sure)
            this.topItemsLoaded = true;

            // Mark the collection is not loading with the fetcher
            this.loading = false;

            // Store the top items in storage using `this.key`
            (async () => {
                try {
                    // Get the sorted top items limited to the one portion amount to save
                    const topItems = this.items.slice(0, this.limit).filter(Boolean);

                    // Check if the top items are not empty and not already saved
                    if (topItems.length > 0 && !isEqual(this.savedTopItems, topItems)) {
                        await this.storageManager.setItem(this.key, JSON.stringify(topItems));
                        this.savedTopItems = topItems;
                    }
                } catch (error) {
                    log.error(
                        'Failed to cache the collection (top items) in the storage with error:',
                        error,
                    );
                    // Do nothing, but log an error
                }
            })();
        }

        const memoized: boolean | undefined = this.topItems
            ? !loadedWithFetcher // The collection has been loaded from the memo storage
            : undefined; // The collection has not been loaded at all

        if (this.listener) {
            this.listener(this.error, this.items, memoized);
        }
    }
}
