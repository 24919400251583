import classnames from 'classnames/bind';
import type { MouseEvent } from 'react';
import { useCallback, memo, forwardRef, HTMLProps } from 'react';

import { Icon } from '../Icon';

import styles from './Dropdown.module.scss';

const cnb = classnames.bind(styles);

type ToggleProps = HTMLProps<HTMLButtonElement> & {
    icon?: string;
    backdrop?: boolean;
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const CustomToggle = forwardRef<HTMLButtonElement, ToggleProps>(
    (
        { children, onClick, disabled, icon = 'more-horizontal', backdrop = false, className },
        ref,
    ) => {
        // Events

        const onClickFn = useCallback(
            (e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                if (onClick && !disabled) onClick(e);
            },
            [onClick, disabled],
        );

        // Render

        return (
            <>
                <button
                    type="button"
                    ref={ref}
                    className={`${cnb('dropdown-toggle', disabled ? 'disabled' : '')} ${className}`}
                    onClick={onClickFn}
                >
                    {children}
                    {icon && <Icon icon={icon} />}
                </button>
                {backdrop && <div className={`modal-backdrop fade ${cnb('dropdown-backdrop')}`} />}
            </>
        );
    },
);

export default memo(CustomToggle);
