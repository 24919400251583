import classNames from 'classnames/bind';
import { memo } from 'react';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import styles from './Footer.module.scss';
import { LandingIcon } from '../LandingIcon';

const cnb = classNames.bind(styles);

export const Footer = memo(function Footer({ links }: { links: Record<string, string> }) {
    return (
        <FlexContainer className={cnb('footer')} direction="column" justify="center" align="center">
            <Flex>
                <LandingIcon icon="mooli-symbol-large" />
            </Flex>
            <Flex className="m-b-2 m-t-2">
                <FlexContainer direction="row" justify="center" align="center">
                    <Flex className="m-r-1">
                        <a
                            href={links.discord}
                            className="text-white hover:color-blockchain"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon icon="discord" />
                        </a>
                    </Flex>
                    <Flex className="m-r-1 m-l-1">
                        <a
                            href={links.telegram}
                            className="text-white hover:color-blockchain"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon icon="telegram" />
                        </a>
                    </Flex>
                    <Flex className="m-r-1 m-l-1">
                        <a
                            href={links.twitter}
                            className="text-white hover:color-blockchain"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon icon="twitter" />
                        </a>
                    </Flex>
                    <Flex className="m-r-1 m-l-1">
                        <a
                            href={links.medium}
                            className="text-white hover:color-blockchain"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon icon="medium" />
                        </a>
                    </Flex>
                    <Flex className="m-r-1 m-l-1">
                        <a
                            href={links.linkedin}
                            className="text-white hover:color-blockchain"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon icon="linkedin" />
                        </a>
                    </Flex>
                    <Flex className="m-l-1">
                        <a
                            href={links.github}
                            className="text-white hover:color-blockchain"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon icon="github" />
                        </a>
                    </Flex>
                </FlexContainer>
            </Flex>
            <Flex className="m-b-2 action action-normal">
                or say, <a href="mailto:hello@mooli.app">hello@mooli.app</a>
            </Flex>
            <Flex className="paragraph paragraph-small m-b-1">© {new Date().getFullYear()}</Flex>
        </FlexContainer>
    );
});
