/* eslint-disable max-lines */
import { memo } from 'react';

export const LandingIcon = memo(function LandingIcon({
    icon,
    className = '',
}: {
    icon: string;
    className?: string;
}) {
    switch (icon.toLocaleLowerCase()) {
        case 'mooli-symbol':
            return (
                <svg
                    className={className}
                    width="40"
                    height="24"
                    viewBox="0 0 40 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.25 12C22.25 16.5563 18.5563 20.25 14 20.25C9.44365 20.25 5.75 16.5563 5.75 12C5.75 7.44365 9.44365 3.75 14 3.75C18.5563 3.75 22.25 7.44365 22.25 12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M37.25 12C37.25 16.5563 33.5563 20.25 29 20.25C24.4437 20.25 20.75 16.5563 20.75 12C20.75 7.44365 24.4437 3.75 29 3.75C33.5563 3.75 37.25 7.44365 37.25 12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M19.25 12C19.25 16.5563 15.5563 20.25 11 20.25C6.44365 20.25 2.75 16.5563 2.75 12C2.75 7.44365 6.44365 3.75 11 3.75C15.5563 3.75 19.25 7.44365 19.25 12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                </svg>
            );
        case 'mooli-symbol-large':
            return (
                <svg
                    className={className}
                    width="120"
                    height="72"
                    viewBox="0 0 120 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M68.249 36C68.249 50.4975 56.4965 62.25 41.999 62.25C27.5015 62.25 15.749 50.4975 15.749 36C15.749 21.5025 27.5015 9.75 41.999 9.75C56.4965 9.75 68.249 21.5025 68.249 36Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M113.25 36C113.25 50.4975 101.497 62.25 87 62.25C72.5025 62.25 60.75 50.4975 60.75 36C60.75 21.5025 72.5025 9.75 87 9.75C101.497 9.75 113.25 21.5025 113.25 36Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M59.25 36C59.25 50.4975 47.4975 62.25 33 62.25C18.5025 62.25 6.75 50.4975 6.75 36C6.75 21.5025 18.5025 9.75 33 9.75C47.4975 9.75 59.25 21.5025 59.25 36Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                </svg>
            );
        case 'portfolio':
            return (
                <svg
                    className={className}
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M37 27.5V20C37 17.2386 34.7615 15 32 15H22C19.2386 15 17 17.2386 17 20V27.5M52 40L27.9805 44.804C27.3333 44.9333 26.6667 44.9333 26.0195 44.804L2 40M2 32.5C2 29.7386 4.23857 27.5 7 27.5H47C49.7615 27.5 52 29.7386 52 32.5V60C52 62.7615 49.7615 65 47 65H7C4.23857 65 2 62.7615 2 60V32.5Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'unlimited':
            return (
                <svg
                    className={className}
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M27.5 40.0247C27.5 40.0247 19.9358 27.8661 10.0167 30.3295C-0.00545144 32.8184 -0.0056982 47.1815 10.0167 49.6695C19.9358 52.1317 27.5 40.0247 27.5 40.0247ZM27.5 40.0247C27.5 40.0247 35.0642 52.1322 44.9832 49.6695C55.0057 47.1812 55.0055 32.8186 44.9832 30.3297C35.0642 27.8664 27.5 40.0247 27.5 40.0247Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'needs':
            return (
                <svg
                    className={className}
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 40C2 31.7157 8.71573 25 17 25H37C45.2842 25 52 31.7157 52 40C52 48.2842 45.2842 55 37 55H17C8.71573 55 2 48.2842 2 40Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M42 40C42 42.7615 39.7615 45 37 45C34.2385 45 32 42.7615 32 40C32 37.2385 34.2385 35 37 35C39.7615 35 42 37.2385 42 40Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'preset':
            return (
                <svg
                    className={className}
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M35 40C35 44.1421 31.6421 47.5 27.5 47.5M35 40C35 35.8579 31.6421 32.5 27.5 32.5M35 40H42.5M27.5 47.5C23.3579 47.5 20 44.1421 20 40M27.5 47.5V55M20 40C20 35.8579 23.3579 32.5 27.5 32.5M20 40H12.5M27.5 32.5V25M27.5 25C30.2614 25 32.5 22.7614 32.5 20C32.5 17.2386 30.2614 15 27.5 15C24.7386 15 22.5 17.2386 22.5 20C22.5 22.7614 24.7386 25 27.5 25ZM42.5 40C42.5 42.7614 44.7386 45 47.5 45C50.2614 45 52.5 42.7614 52.5 40C52.5 37.2386 50.2614 35 47.5 35C44.7386 35 42.5 37.2386 42.5 40ZM12.5 40C12.5 42.7614 10.2614 45 7.5 45C4.73858 45 2.5 42.7614 2.5 40C2.5 37.2386 4.73858 35 7.5 35C10.2614 35 12.5 37.2386 12.5 40ZM27.5 55C24.7386 55 22.5 57.2386 22.5 60C22.5 62.7614 24.7386 65 27.5 65C30.2614 65 32.5 62.7614 32.5 60C32.5 57.2386 30.2614 55 27.5 55Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'wealth':
            return (
                <svg
                    className={className}
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.25 18.75L27 17.5L40.75 18.75M13.25 18.75L2 30M13.25 18.75L27 62.5M40.75 18.75L27 62.5M40.75 18.75L52 30M2 30L27 62.5M2 30H52M27 62.5L52 30"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'folder':
            return (
                <svg
                    className={className}
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.5 62.5H47.5C50.2615 62.5 52.5 60.2615 52.5 57.5V30C52.5 27.2386 50.2615 25 47.5 25H25L20.7422 18.6133C20.2785 17.9178 19.4979 17.5 18.6621 17.5H7.5C4.73857 17.5 2.5 19.7386 2.5 22.5V57.5C2.5 60.2615 4.73857 62.5 7.5 62.5Z"
                        stroke="white"
                        strokeWidth="1.75"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'location':
            return (
                <svg
                    className={className}
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.08098 48.2183L22 65L36.919 48.2183C48.3615 35.347 39.2232 15 22 15C4.77671 15 -4.36154 35.347 7.08098 48.2183Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M21.9997 42.5C26.1419 42.5 29.4997 39.1421 29.4997 35C29.4997 30.8579 26.1419 27.5 21.9997 27.5C17.8576 27.5 14.4997 30.8579 14.4997 35C14.4997 39.1421 17.8576 42.5 21.9997 42.5Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'search':
            return (
                <svg
                    className={className}
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M40 52.5L52.5 65L40 52.5ZM46.25 36.875C46.25 48.9562 36.4562 58.75 24.375 58.75C12.2938 58.75 2.5 48.9562 2.5 36.875C2.5 24.7938 12.2938 15 24.375 15C36.4562 15 46.25 24.7938 46.25 36.875Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'shield':
            return (
                <svg
                    className={className}
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 42.5V20.5555L6.8593 20.1806C11.0927 19.8539 15.2472 18.8553 19.1667 17.2222L24.5 15L29.8332 17.2222C33.7527 18.8553 37.9072 19.8539 42.1407 20.1806L47 20.5555V42.5C47 54.9265 36.9265 65 24.5 65C12.0736 65 2 54.9265 2 42.5Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'mark':
            return (
                <svg
                    className={className}
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.5 65V17.5C2.5 16.1193 3.6193 15 5 15H35C36.3807 15 37.5 16.1193 37.5 17.5V65L20 49.7222L2.5 65Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'link':
            return (
                <svg
                    className={className}
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.2465 28.7397L23.8768 23.1095C28.0228 18.9635 34.7445 18.9635 38.8905 23.1095C43.0365 27.2554 43.0365 33.9773 38.8905 38.1233L33.2603 43.7535M8.25 38.75L5.10945 41.8768C0.963528 46.0228 0.963503 52.7445 5.10945 56.8905C9.2554 61.0365 15.9773 61.0365 20.1233 56.8905L23.25 53.75M15.75 46.25L28.25 33.75"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'share':
            return (
                <svg
                    className={className}
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.5 45V60C2.5 62.7615 4.73857 65 7.5 65H42.5C45.2615 65 47.5 62.7615 47.5 60V45M25 52.5V17.5M25 17.5L12.5 31.1111M25 17.5L37.5 31.1111"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'scan':
            return (
                <svg
                    className={className}
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 6H6V12M4 24H44M30 18V10M24 18V16M18 18V14M24 12V6M36 6H42V12M12 42H6V36M36 42H42V36"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'coins':
            return (
                <svg
                    className={className}
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.038 18C17.8338 15.3514 18.4458 12.7039 19.7918 10.4137C21.1377 8.12347 23.153 6.30057 25.5663 5.19033C27.9796 4.08009 30.675 3.73582 33.2899 4.20384C35.9048 4.67186 38.3135 5.92971 40.1919 7.80809C42.0703 9.68647 43.3281 12.0952 43.7962 14.7101C44.2642 17.325 43.9199 20.0204 42.8097 22.4337C41.6994 24.847 39.8765 26.8623 37.5863 28.2082C35.2961 29.5542 32.6486 30.1662 30 29.962M44 34C44 35.5913 43.3679 37.1174 42.2426 38.2426C41.1174 39.3679 39.5913 40 38 40H34M34 40L38 36M34 40L38 44M4 14C4 12.4087 4.63214 10.8826 5.75736 9.75736C6.88258 8.63214 8.4087 8 10 8H14M14 8L10 12M14 8L10 4M17 44C13.5522 44 10.2456 42.6304 7.80761 40.1924C5.36964 37.7544 4 34.4478 4 31C4 27.5522 5.36964 24.2456 7.80761 21.8076C10.2456 19.3696 13.5522 18 17 18C20.4478 18 23.7544 19.3696 26.1924 21.8076C28.6304 24.2456 30 27.5522 30 31C30 34.4478 28.6304 37.7544 26.1924 40.1924C23.7544 42.6304 20.4478 44 17 44Z"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'sun':
            return (
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5935_11935)">
                        <path
                            d="M44 24H46M24 4V2M24 46V44M40 40L38 38M40 8L38 10M8 40L10 38M8 8L10 10M2 24H4M24 36C27.1826 36 30.2348 34.7357 32.4853 32.4853C34.7357 30.2348 36 27.1826 36 24C36 20.8174 34.7357 17.7652 32.4853 15.5147C30.2348 13.2643 27.1826 12 24 12C20.8174 12 17.7652 13.2643 15.5147 15.5147C13.2643 17.7652 12 20.8174 12 24C12 27.1826 13.2643 30.2348 15.5147 32.4853C17.7652 34.7357 20.8174 36 24 36Z"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5935_11935">
                            <rect width="48" height="48" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        default:
            return null;
    }
});
