/**
 * Validate email
 * @param email - email to validate
 * @returns true if email is valid, false otherwise
 * https://docs.getwaitlist.com/api-docs/sandbox#general-waitlist-example
 */

export function validateEmail(email: string) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }
    return false;
}
