import { ReactNode, useEffect } from 'react';
import classnames from 'classnames/bind';

import { Panel } from '../Panel';

import styles from './ModalSheet.module.scss';

const cnb = classnames.bind(styles);

interface ModalSheetProps {
    show: boolean;
    onHide?: () => void;
    className?: string;
    header?: ReactNode;
    children: ReactNode;
}

export function ModalSheet({ show, onHide, className, header, children }: ModalSheetProps) {
    useEffect(() => {
        if (show && onHide) onHide();
    }, [onHide, show]);

    return (
        <Panel className={cnb('modal-sheet', className, { active: show })} header={header}>
            {children}
        </Panel>
    );
}
