/* eslint-disable @typescript-eslint/no-shadow, import/no-unresolved */
const customEnv: { [key: string]: unknown } = {};

export enum BuildType {
    Development = 'development', // This is a local one, not sent to CI
    Alpha = 'alpha',
    ClosedBeta = 'closedBeta',
    Beta = 'beta',
    Release = 'release',
    Translate = 'translate',
}

enum Keys {
    BUILD_TYPE = 'BUILD_TYPE',
    IS_E2E = 'IS_E2E',
    NODE_ENV = 'NODE_ENV',
    OBSERVE_PERFORMANCE = 'OBSERVE_PERFORMANCE',
    DISABLE_REDIRECT_TO_DESKTOP = 'DISABLE_REDIRECT_TO_DESKTOP',
}
export class Environment {
    public static setEnv(env: { [key: string]: unknown }) {
        Object.assign(customEnv, env);
    }

    public static getEnv(key: string, defaultValue?: string): string {
        return `${customEnv[key] || defaultValue || ''}`.toLowerCase();
    }

    public static get buildType(): BuildType {
        // Set `development` as the default in case the BUILD_TYPE property is not passed
        return (
            // React App can use only `REACT_APP_` variables
            (this.getEnv(Keys.BUILD_TYPE, process.env.REACT_APP_BUILD_TYPE) as BuildType) ||
            (this.getEnv(Keys.BUILD_TYPE, process.env.BUILD_TYPE) as BuildType) ||
            BuildType.Development
        );
    }

    public static get isAlpha(): boolean {
        return this.buildType === BuildType.Alpha;
    }

    public static get isClosedBeta(): boolean {
        return this.buildType === BuildType.ClosedBeta;
    }

    public static get isBeta(): boolean {
        return this.buildType === BuildType.Beta;
    }

    public static get isRelease(): boolean {
        return this.buildType === BuildType.Release;
    }

    public static get isTranslation(): boolean {
        return this.buildType === BuildType.Translate;
    }

    public static get isDevelopment(): boolean {
        return this.getEnv(Keys.NODE_ENV, process.env.NODE_ENV) === 'development';
    }

    public static get isProduction(): boolean {
        return this.getEnv(Keys.NODE_ENV, process.env.NODE_ENV) === 'production';
    }
}
