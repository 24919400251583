import { useEffect, useRef } from 'react';

export type Status = 'idle' | 'loading' | 'ready' | 'error';
export type ScriptElt = HTMLScriptElement | null;

/**
 * Hook to attach a script.
 * @param src - a script to attach.
 * @param option - script tag attributes.
 * @returns the status of the script.
 */
export function useScript(src: string, option: Record<string, string | boolean> = {}): void {
    const status = useRef<Status>(src ? 'loading' : 'idle');
    useEffect(() => {
        if (!src) {
            status.current = 'idle';
            return undefined;
        }

        // Fetch existing script element by src
        // It may have been added by another instance of this hook
        let script: ScriptElt = document.querySelector(`script[src="${src}"]`);

        if (!script) {
            // Create script
            script = document.createElement('script');
            script.src = src;
            script.async = true;

            Object.keys(option).forEach((key: string) => {
                if (key === 'dataCode')
                    if (script) script.setAttribute('data-code', option[key] as string);
                if (key === 'id') if (script) script.id = option[key] as string;
                if (key === 'type') if (script) script.type = option[key] as string;
                if (key === 'noModule') if (script) script.noModule = option[key] as boolean;
                if (key === 'integrity') if (script) script.integrity = option[key] as string;
                if (key === 'crossorigin') if (script) script.crossOrigin = option[key] as string;
            });

            script.setAttribute('data-status', 'loading');
            // Add script to document body
            document.body.appendChild(script);

            // Store status in attribute on script
            // This can be read by other instances of this hook
            const setAttributeFromEvent = (event: Event) => {
                script?.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
            };

            script.addEventListener('load', setAttributeFromEvent);
            script.addEventListener('error', setAttributeFromEvent);
        } else {
            // Grab existing script status from attribute and set to state.
            status.current = script.getAttribute('data-status') as Status;
        }

        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event: Event) => {
            status.current = event.type === 'load' ? 'ready' : 'error';
        };

        // Add event listeners
        script.addEventListener('load', setStateFromEvent);
        script.addEventListener('error', setStateFromEvent);

        // Remove event listeners on cleanup
        return () => {
            if (script) {
                script.removeEventListener('load', setStateFromEvent);
                script.removeEventListener('error', setStateFromEvent);
            }
        };
    }, [src, option]);
}

export default useScript;
