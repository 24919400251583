import classnames from 'classnames/bind';
import { memo, InputHTMLAttributes } from 'react';

import styles from './Flex.module.scss';

const cnb = classnames.bind(styles);

export interface FlexProps extends InputHTMLAttributes<HTMLInputElement> {
    grow?: number;
    shrink?: number;
}
export interface FlexContainerProps extends InputHTMLAttributes<HTMLInputElement> {
    direction?: string;
    justify?: string;
    align?: string;
}

export const FlexContainer = memo(function FlexContainer({
    direction,
    justify,
    style,
    align,
    children,
    className,
    ...props
}: FlexContainerProps) {
    return (
        <div
            className={cnb(
                'flex-container',
                { 'flex-container-row': direction === 'row' },
                { 'flex-container-column': direction === 'column' },
                className,
            )}
            style={{
                justifyContent: justify,
                alignItems: align,
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
});

export const Flex = memo(function Flex({ grow, shrink, children, ...props }: FlexProps) {
    return (
        <div
            className={cnb('flex')}
            style={{
                flexGrow: grow,
                flexShrink: shrink,
            }}
            {...props}
        >
            {children}
        </div>
    );
});
