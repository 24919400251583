import { Log } from '@smartfolly/common.utilities';

export const WAITLIST_URL = 'https://api.getwaitlist.com/api/v1/waiter';
export const WAITLIST_ID = 9123;

const log = new Log('Get Waitlist');

export type WaitlistEntry = {
    amount_referred: number;
    answers: WaitlistAnswer[];
    created_at: string;
    email: string;
    priority: number;
    referral_link: string;
    referral_token: string;
    referred_by_waiter_token: string;
    removed_date: string | null;
    removed_priority: number | null;
    uuid: string;
    verified: boolean;
    waitlist_id: number;
};

export type Waiter = {
    email: string;
    waitlist_id?: number;
    first_name?: string | undefined;
    last_name?: string | undefined;
    phone?: string;
    referral_link?: string;
    metadata?: Record<string, unknown>;
    answers?: {
        question_value: string;
        optional: boolean;
        answer_value: unknown;
    }[];
};

export type WaitlistAnswer = {
    question_value: string;
    optional: boolean;
    answer_value: unknown;
};

/**
 * Create a waiter. This will add the user to the waitlist.
 * @param data - waiter's data - name, email, etc.
 * @returns waiter entry or null if error
 */

export async function createWaiter({
    email,
    name,
}: {
    email: string;
    name: string;
}): Promise<WaitlistEntry | null> {
    try {
        const response = await fetch(WAITLIST_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                waitlist_id: WAITLIST_ID,
                referral_link: document.URL,
                answers: [
                    {
                        question_value: 'Your name',
                        optional: true,
                        answer_value: name ?? '',
                    },
                ],
            } as Waiter),
        });

        if (!response.ok) {
            throw new Error('Get Waitlist network response was not ok');
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        log.error('Get Waitlist error:', error);
        return null;
    }
}
