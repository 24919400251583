import classnames from 'classnames/bind';
import {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    cloneElement,
    forwardRef,
    memo,
    useMemo,
} from 'react';

import styles from './Button.module.scss';

const cnb = classnames.bind(styles);

export declare type CommonButtonProps = 'href' | 'size' | 'variant' | 'disabled';

export interface IButtonIcon {
    icon: React.ReactNode;
    animation?: 'none' | 'left' | 'right';
}

export interface IButton
    extends Omit<
        DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
        'onSelect'
    > {
    type?: 'button' | 'submit' | 'reset';
    active?: boolean;
    size?: 'small' | 'medium' | 'large';
    variant?: 'button' | 'action';
    color?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'failure'
        | 'transparent'
        | 'clear'
        | 'telegram';
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
    iconLeft?: IButtonIcon | undefined;
    iconRight?: IButtonIcon | undefined;
}

const LeftIconContainer = memo(function LeftIconContainer({ iconLeft }: Pick<IButton, 'iconLeft'>) {
    if (!iconLeft) {
        return null;
    }

    return cloneElement(iconLeft.icon as React.ReactElement, {
        className: cnb(
            'icon-left',
            (iconLeft.icon as React.ReactElement).props
                ? (iconLeft.icon as React.ReactElement).props.className
                : '',
        ),
    });
});

const RightIconContainer = memo(function RightIconContainer({
    iconRight,
}: Pick<IButton, 'iconRight'>) {
    if (!iconRight) {
        return null;
    }

    return cloneElement(iconRight.icon as React.ReactElement, {
        className: cnb(
            'icon-right',
            (iconRight.icon as React.ReactElement).props
                ? (iconRight.icon as React.ReactElement).props.className
                : '',
        ),
    });
});

const ButtonContentContainer = memo(function ButtonContainer({
    variant,
    children,
}: Pick<IButton, 'variant' | 'children'>) {
    if (variant === 'action') {
        return <span>{children}</span>;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
});

export const Button = forwardRef(function Button(
    {
        disabled,
        type,
        size = 'medium',
        variant = 'button',
        color = 'default',
        children,
        iconLeft,
        iconRight,
        onClick,
        className,
        ...props
    }: IButton,
    ref: React.Ref<HTMLButtonElement>,
) {
    const classNames = useMemo(() => {
        return cnb(
            'button',
            `button-${color}`,
            `button-${size}`,
            children ? 'button' : 'button-icon-only',
            `button-variant-${variant}`,
            { 'button-icon-left': iconLeft },
            { 'button-icon-right': iconRight },
            'action',
            (btnSize => {
                switch (btnSize) {
                    case 'small':
                        return 'action-small';
                    case 'medium':
                        return 'action-medium';
                    case 'large':
                        return 'action-normal';
                    default:
                        return 'action-medium';
                }
            })(size),
            className,
        );
    }, [children, className, color, iconLeft, iconRight, size, variant]);

    return (
        <button
            ref={ref}
            type={type === 'button' ? 'button' : 'submit'}
            onClick={onClick}
            disabled={disabled}
            className={classNames}
            {...props}
        >
            <LeftIconContainer iconLeft={iconLeft} />
            <ButtonContentContainer variant={variant}>{children}</ButtonContentContainer>
            <RightIconContainer iconRight={iconRight} />
        </button>
    );
});
