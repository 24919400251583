/* eslint-disable max-lines */
import { Button, Flex, FlexContainer, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';
import classnames from 'classnames/bind';
import { MotionValue, motion, useScroll, useTransform } from 'framer-motion';

import styles from './Home.module.scss';

import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';

import topBall from '../assets/images/bg1.svg';
import middleBall from '../assets/images/bg2.svg';
import bottomBall from '../assets/images/bg3.svg';

import bg2Text from '../assets/images/bg2-text.svg';
import bg3Text from '../assets/images/bg3-text.svg';

import { Footer, Header, LandingIcon } from '../components';

const cnb = classnames.bind(styles);

const openApp = () => {
    window.open('//beta.mooli.app/', '_blank');
};

const openDemo = () => {
    window.open(
        '//beta.mooli.app/share/be1826b5d268759115cde348282261c893a580c07ff6a55efdb0c48f0a0191ab',
        '_blank',
    );
};

const openDiscord = () => {
    window.open('//discord.com/invite/dRJczHKmVB', '_blank');
};

const iconArrowRight: IButtonIcon = {
    icon: <Icon icon="arrow-right-mini" className="m-r-0" />,
    animation: 'none',
};

const LINKS: Record<string, string> = {
    discord: '//discord.com/invite/dRJczHKmVB',
    twitter: '//twitter.com/mooli_app',
    telegram: '//t.me/mooli_app',
    medium: '//mooliapp.medium.com',
    linkedin: '//www.linkedin.com/company/mooliapp',
    github: '//github.com/mooliapp',
};

export const Home = function Home() {
    const { scrollYProgress } = useScroll();
    const imgRotation: MotionValue<number> | 'none' = useTransform(
        scrollYProgress,
        [0, 0.5, 1],
        [-90, 0, 90],
        { clamp: false },
    );

    return (
        <>
            <Header openApp={openDemo} />
            <div className={cnb('main-wrapper')}>
                {/* First ball - two slides */}
                <div className={cnb('relative-wrapper')}>
                    <img src={topBall} alt="" className={cnb('top-ball')} />
                    {/* Slide 1 */}
                    <div className={cnb('slide', 'slide-1')}>
                        <FlexContainer
                            direction="column"
                            justify="center"
                            align="center"
                            className={cnb('container')}
                        >
                            <Flex className="text-center">
                                <div className="headline headline-biggest text-center">
                                    One app, all&nbsp;your digital assets
                                </div>
                                <div className="title title-large text-center m-t-2">
                                    No seed-phrase. No private keys. No personal data. <br />
                                    Stress-free tracking of your digital assets.
                                </div>
                            </Flex>
                            <Flex className={cnb('slide-1-btn-wrapper')}>
                                <Button
                                    onClick={openApp}
                                    className="b-r-6 landing-btn filled bigger"
                                >
                                    <span className="action action-large">Track Now</span>
                                </Button>
                            </Flex>
                        </FlexContainer>
                    </div>
                    {/* Slide 2 */}
                    <div className={cnb('slide', 'slide-2', 'customize-slide')}>
                        <FlexContainer
                            direction="column"
                            justify="center"
                            align="center"
                            className={cnb('container')}
                        >
                            <Flex>
                                <div className="headline headline-biggest text-center">
                                    One-stop-shop
                                    <br />
                                    for&nbsp;your crypto
                                </div>
                            </Flex>
                            <Flex className="title title-large text-center">
                                We create the path for your personal and professional growth,
                                enabling you to&nbsp;pursue your aspirations and enjoy it while
                                spending less time on it.
                            </Flex>
                            <Flex>
                                <div className={cnb('info-cards')}>
                                    <div className={cnb('info-card')}>
                                        <LandingIcon icon="scan" />
                                        <div className="title title-large m-b-1 m-t-1">
                                            Analyze portfolio
                                        </div>
                                        <div className={cnb('card-text')}>
                                            You bring a public wallet address, we hook you up with
                                            all the cool stats. Structure portfolio with customized
                                            boards. Pick fiat currency you like to keep an eye on
                                            your funds.
                                        </div>
                                    </div>
                                    <div className={cnb('info-card')}>
                                        <LandingIcon icon="coins" />
                                        <div className="title title-large m-b-1 m-t-1">
                                            Use DeFi tools
                                        </div>
                                        <div className={cnb('card-text')}>
                                            Look no further as we&apos;ve got everything you need
                                            right here! Whether you want to buy, swap, bridge, sell,
                                            or stake, we&apos;ve got your back. We&apos;ll be your
                                            trusty guides, showing you the way.
                                        </div>
                                    </div>
                                    <div className={cnb('info-card')}>
                                        <LandingIcon icon="sun" />
                                        <div className="title title-large m-b-1 m-t-1">
                                            Gain insights
                                        </div>
                                        <div className={cnb('card-text')}>
                                            Looking to dive deeper into the market but not sure
                                            where to start? We&apos;re here to&nbsp;provide you with
                                            all the info you need, structured, so you can make
                                            decisions with confidence.
                                        </div>
                                    </div>
                                </div>
                            </Flex>
                            <Flex>
                                <div className="headline headline-little text-center m-b-4">
                                    Sit back, relax, <br />
                                    we’ll do all for you!
                                </div>
                            </Flex>
                            <Flex className={cnb('slide-2-btn-wrapper')}>
                                <Button onClick={openApp} className="b-r-6 landing-btn">
                                    <span className="action action-normal">Get started</span>
                                </Button>
                            </Flex>
                        </FlexContainer>
                    </div>
                </div>
                {/* Seconda ball - two slides */}
                <div className={cnb('relative-wrapper')}>
                    <img src={middleBall} alt="" className={cnb('middle-ball')} />
                    <motion.img
                        src={bg2Text}
                        className={cnb('bg2Text')}
                        style={{
                            rotate: imgRotation,
                        }}
                    />
                    {/* Slide 3 */}
                    <div className={cnb('slide', 'slide-3')}>
                        <div className={cnb('container')}>
                            <FlexContainer
                                className={cnb('bullets-and-image')}
                                direction="row"
                                justify="center"
                                align="center"
                            >
                                <Flex className={cnb('bullets')}>
                                    <FlexContainer
                                        className={cnb('bullets-row')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="portfolio" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Create customized portfolio
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="unlimited" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Use unlimited boards and&nbsp;wallets
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                    <FlexContainer
                                        className={cnb('bullets-row')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="needs" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Adjust according to your personal needs
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="preset" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Inspire with ready-to-use asset groups
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                    <div className={cnb('slide-3-btn-wrapper')}>
                                        <Button
                                            onClick={openApp}
                                            className="m-t-2 landing-btn b-r-6"
                                            iconRight={iconArrowRight}
                                        >
                                            <span className="action action-normal">
                                                Customize board
                                            </span>
                                        </Button>
                                    </div>
                                </Flex>
                                <Flex className={cnb('image')}>
                                    <img src={image2} alt="3" className="img-fluid" />
                                </Flex>
                            </FlexContainer>
                        </div>
                    </div>
                    {/* Slide 4 */}
                    <div className={cnb('slide', 'slide-4')}>
                        <div className={cnb('container')}>
                            <FlexContainer
                                direction="row"
                                justify="center"
                                align="center"
                                className={cnb('bullets-and-image', 'reversed', 'bullets-spaces')}
                            >
                                <Flex className={cnb('image')}>
                                    <img src={image3} alt="3" className="img-fluid" />
                                </Flex>
                                <Flex className={cnb('bullets')}>
                                    <FlexContainer
                                        className={cnb('bullets-row')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="wealth" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Know your digital wealth anytime
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="folder" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                View all assets in groups
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                    <FlexContainer
                                        className={cnb('bullets-row')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="location" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Check token location
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="search" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Track any public wallet
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                    <div className={cnb('slide-4-btn-wrapper')}>
                                        <Button
                                            onClick={openApp}
                                            className="b-r-6 landing-btn"
                                            iconRight={iconArrowRight}
                                        >
                                            <span className="action action-normal">Track now</span>
                                        </Button>
                                    </div>
                                </Flex>
                            </FlexContainer>
                        </div>
                    </div>
                </div>
                {/* Third ball - rest slides */}

                <div className={cnb('relative-wrapper')}>
                    <img src={bottomBall} alt="" className={cnb('bottom-ball')} />
                    <motion.img
                        src={bg3Text}
                        className={cnb('bg3Text')}
                        style={{
                            rotate: imgRotation,
                        }}
                    />
                    {/* Slide 5 */}
                    <div className={`${cnb('slide', 'slide-5', 'top-spaced-huge')}`}>
                        <div className={cnb('container')}>
                            <FlexContainer
                                className={cnb('bullets-and-image')}
                                direction="row"
                                justify="center"
                                align="center"
                            >
                                <Flex className={cnb('bullets')}>
                                    <FlexContainer
                                        className={cnb('bullets-row')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="shield" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                We do not get access to&nbsp;your keys and seed
                                                phrase
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="mark" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                All airdrops and scam tokens will be marked out
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                    <FlexContainer
                                        className={cnb('bullets-row')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="link" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                One secure session link for all devices
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('bullet')}>
                                            <LandingIcon icon="share" />
                                            <div className="paragraph paragraph-normal m-t-0.5">
                                                Generate public link to&nbsp;share selected board
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                    <div className={cnb('slide-5-btn-wrapper')}>
                                        <Button
                                            onClick={openApp}
                                            className="b-r-6 landing-btn"
                                            iconRight={iconArrowRight}
                                        >
                                            <span className="action action-normal">
                                                Try it safely
                                            </span>
                                        </Button>
                                    </div>
                                </Flex>
                                <Flex className={cnb('image')}>
                                    <img src={image4} alt="4" className="img-fluid" />
                                </Flex>
                            </FlexContainer>
                        </div>
                    </div>
                    {/* Slide 6 */}
                    <div className={cnb('slide', 'slide-6')}>
                        <div className={cnb('container')}>
                            <FlexContainer
                                direction="column"
                                justify="center"
                                align="center"
                                className="m-t-4"
                            >
                                <Flex className="headline headline-little text-center">
                                    Explore new crypto experience
                                </Flex>
                                <Flex className="paragraph paragraph-large m-t-1">
                                    It takes less than 5 seconds
                                </Flex>
                                <Flex className="m-t-4">
                                    <Button
                                        onClick={openDiscord}
                                        className="b-r-6 landing-btn"
                                        iconRight={iconArrowRight}
                                    >
                                        <span className="action action-normal">Subscribe</span>
                                    </Button>
                                </Flex>
                            </FlexContainer>
                        </div>
                    </div>
                    {/* Slide 7 */}
                    <div className={cnb('slide', 'slide-7', 'faq-slide')}>
                        <div className={cnb('container')}>
                            <FlexContainer direction="column" justify="center" align="center">
                                <Flex className="headline headline-little text-center">F.A.Q</Flex>
                                <Flex className="m-t-4">
                                    <FlexContainer
                                        className={cnb('faq')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('faq-column')}>
                                            <div className={cnb('bordered-block')}>
                                                <div className="title title-huge m-b-1">
                                                    What are board for?
                                                </div>
                                                <p>
                                                    A board is a type of &quot;folder&quot; for
                                                    assets that users create when planning their
                                                    personal needs or investment portfolio.
                                                </p>
                                                <p>
                                                    Boards can be used to structure investment
                                                    portfolios, achieve necessary diversification,
                                                    plan purchases, and monitor current asset
                                                    allocation.
                                                </p>
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('faq-column')}>
                                            <div className={cnb('bordered-block')}>
                                                <div className="title title-huge m-b-1">
                                                    Why is it safe?
                                                </div>
                                                <p>
                                                    We only require your public wallet address to
                                                    read open data and provide information about
                                                    your portfolio and analytics. Mooli will never
                                                    ask for your private keys or seed phrase.No
                                                    direct access, no danger.
                                                </p>
                                            </div>
                                            <div className={cnb('bordered-block')}>
                                                <div className="title title-huge m-b-1">
                                                    Is it free?
                                                </div>
                                                <p>
                                                    Yes, Mooli’s basic tracking features are free
                                                    for&nbsp;everyone.
                                                </p>
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                </Flex>
                            </FlexContainer>
                        </div>
                    </div>
                    {/* Slide 8 */}
                    <div className={cnb('slide', 'slide-8', 'get-started-slide')}>
                        <div className={cnb('container')}>
                            <FlexContainer direction="column" justify="center" align="center">
                                <Flex className="headline headline-little text-center">
                                    How to get started?
                                </Flex>
                                <Flex className="m-t-4">
                                    <FlexContainer
                                        className={cnb('get-started')}
                                        direction="row"
                                        justify="center"
                                        align="start"
                                    >
                                        <Flex className={cnb('get-started-step')}>
                                            <div className="title title-huge">01.</div>
                                            <div className="paragraph paragraph-normal">
                                                Go to{' '}
                                                <a
                                                    href="//beta.mooli.app"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    beta.mooli.app
                                                </a>{' '}
                                                and&nbsp;either add your public wallet address or
                                                connect via&nbsp;your&nbsp;wallet
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('get-started-step')}>
                                            <div className="title title-huge">02.</div>
                                            <div className="paragraph paragraph-normal">
                                                Use the top right button to add more wallets and
                                                check token location in one click
                                            </div>
                                        </Flex>
                                        <Flex className={cnb('get-started-step')}>
                                            <div className="title title-huge">03.</div>
                                            <div className="paragraph paragraph-normal">
                                                Utilize our pre-made set of boards or create new
                                                boards using personalized settings
                                            </div>
                                        </Flex>
                                    </FlexContainer>
                                </Flex>
                                <Flex className="m-t-4">
                                    <Button
                                        onClick={openApp}
                                        className="b-r-6 landing-btn"
                                        iconRight={iconArrowRight}
                                    >
                                        <span className="action action-normal">Enjoy</span>
                                    </Button>
                                </Flex>
                            </FlexContainer>
                        </div>
                    </div>
                    {/* Slide 9 */}
                    <div className={cnb('slide', 'slide-9', 'last')}>
                        <div className={cnb('container')}>
                            <Footer links={LINKS} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
