import { toast } from 'react-toastify';
import type { ToastOptions } from 'react-toastify';

/**
 * Function to show the toast message on top of the app.
 * @param message - a message to show in the toast.
 * @param config - an optional toast config.
 */
export function showToast(
    message: string,
    config: ToastOptions = {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        theme: 'dark',
        draggable: true,
        draggablePercent: 40,
        draggableDirection: 'x',
    },
) {
    toast(message, config);
}
