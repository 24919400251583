import classNames from 'classnames/bind';
import { memo, useState, useCallback } from 'react';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import styles from './Header.module.scss';
import { LandingIcon } from '../LandingIcon';
import { GetProModal } from '../Common';

const cnb = classNames.bind(styles);

const iconArrowRight: IButtonIcon = {
    icon: <Icon icon="arrow-right-mini" className="m-r-0" />,
    animation: 'none',
};
const iconRocket: IButtonIcon = {
    icon: <Icon icon="rocket-mini" className="m-l-0" />,
    animation: 'none',
};
export const Header = memo(function Header({ openApp }: { openApp: () => void }) {
    const [showGetPro, setShowGetPro] = useState<boolean>(false);
    const onShowGetPro = useCallback(() => setShowGetPro(true), []);
    const onHideGetPro = useCallback(() => setShowGetPro(false), []);
    return (
        <>
            <nav className={`navbar fixed-top ${cnb('header')}`}>
                <div className="m-l-1.5">
                    <a
                        className="text-white hover:text-blockchain"
                        href="https://mooli.app"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LandingIcon icon="mooli-symbol" />
                    </a>
                </div>
                <div className="m-r-1.5">
                    <Button
                        className={`${cnb('get-pro-btn')} landing-btn filled b-r-6 m-r-1`}
                        onClick={onShowGetPro}
                        iconLeft={iconRocket}
                    >
                        <span className="action action-normal">GET PRO</span>
                    </Button>
                    <Button
                        className={`${cnb('header-btn')} landing-btn b-r-6`}
                        onClick={openApp}
                        iconRight={iconArrowRight}
                    >
                        <span className="action action-normal">TRY DEMO</span>
                    </Button>
                </div>
            </nav>
            {showGetPro && <GetProModal hide={onHideGetPro} />}
        </>
    );
});
