import { memo } from 'react';
import classnames from 'classnames/bind';
import styles from './Skeleton.module.scss';

const cn = classnames.bind(styles);

export const Skeleton = memo(function Skeleton({
    className,
    size = 'normal',
    variant = 'normal',
    wide = 'normal',
}: {
    size?: 'large' | 'normal';
    wide?: 'half' | 'triplet' | 'normal';
    variant?: 'primary' | 'normal' | 'green' | 'red';
    className?: string;
}) {
    return (
        <div
            className={cn(
                className,
                'skeleton',
                `skeleton-${variant}`,
                `skeleton-height-${size}`,
                wide ? `skeleton-width-${wide}` : null,
            )}
        />
    );
});
